@keyframes lightA {
  0% {
    transform: rotateZ(-5deg)rotateX(20deg);
  }

  50% {
    transform: rotateZ(-15deg)rotateX(30deg);
  }

  100% {
    transform: rotateZ(-5deg)rotateX(20deg);
  }
}

@keyframes lightB {
  0% {
    transform: rotateZ(5deg)rotateX(20deg);
  }

  50% {
    transform: rotateZ(15deg)rotateX(30deg);
  }

  100% {
    transform: rotateZ(5deg)rotateX(20deg);
  }
}

@keyframes glow {
  0% {
    background: radial-gradient(circle at 50% 57%, #da693533 0%, #000000fc 20%);
  }

  20% {
    background: radial-gradient(circle at 50% 57%, #da693540 0%, #000000fc 20.75%);
  }

  40% {
    background: radial-gradient(circle at 50% 57%, #da693533 0%, #000000fc 20.5%);
  }

  60% {
    background: radial-gradient(circle at 50% 57%, #da69353b 0%, #000000fc 20.25%);
  }

  80% {
    background: radial-gradient(circle at 50% 57%, #da693533 0%, #000000fc 20.5%);
  }

  100% {
    background: radial-gradient(circle at 50% 57%, #da693533 0%, #000000fc 20%);
  }
}

html, body {
  width: 100%;
  height: 100%;
  user-select: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  text-align: center;
  background: linear-gradient(-20deg, #1697be 0%, #0c7392 100%);
  box-shadow: inset 0 0 0 10px #fff;
}

.wrapper {
  width: 100%;
  z-index: 9;
  position: absolute;
  bottom: 10%;
}

.text {
  z-index: 9999;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  color: #fff;
  z-index: 9999;
  margin-top: 20px;
  font-family: Amatic SC, cursive;
  font-size: 4rem;
}

p {
  color: #fff;
  z-index: 9999;
  margin: 15px 0;
  font-family: Amatic SC, cursive;
  font-size: 1.5rem;
}

.message {
  width: 260px;
  margin: 0 auto;
}

.flame-wrapper {
  z-index: 9999;
  position: relative;
  top: 28px;
}

.flames {
  filter: blur(1px);
}

.confetti, .sparkler, .balloons {
  position: absolute;
  top: 0;
  left: 0;
}

.balloons {
  z-index: 1;
}

.sparkler {
  z-index: 9999;
  top: 100px;
}

.candles {
  width: 100%;
  -webkit-text-stroke: 4px #771f18;
  text-stroke: 4px #771f18;
  font-family: Titan One, cursive;
  position: absolute;
}

.candles div {
  color: #fff;
  font-size: 4rem;
  display: inline-block;
  position: relative;
  top: 5px;
}

.candles div:before {
  content: "";
  width: 2px;
  height: 10px;
  background: linear-gradient(#000 0%, #fff9e2 100%);
  display: block;
  position: absolute;
  top: 5px;
  left: 50%;
}

.candles div:nth-child(1) {
  transform: rotate(-2deg);
}

.candles div:nth-child(2) {
  transform: rotate(5deg);
}

.sparkler-sticks div {
  width: 2px;
  height: 100px;
  background: #444;
  position: absolute;
  top: 180px;
  left: 50%;
}

.sparkler-sticks div:nth-child(1) {
  margin-top: 15px;
  margin-left: 95px;
  transform: rotate(13deg);
}

.sparkler-sticks div:nth-child(2) {
  margin-left: 80px;
  transform: rotate(5deg);
}

.cake {
  width: 200px;
  height: 125px;
  background: linear-gradient(90deg, #771f18 0%, #83251e 33%, #771f18 100%);
  border-radius: 7px 7px 2px 2px;
  margin: 4rem auto auto;
  position: relative;
  overflow: hidden;
}

.cake__band {
  width: 200px;
  height: 20px;
  background: linear-gradient(90deg, #771f18 0%, #972a22 33%, #771f18 100%);
  display: none;
  position: relative;
  top: 100px;
}

.cake__top-frosting {
  width: 100%;
  height: 20px;
  background: #e6ddb9;
  position: absolute;
  top: 0;
  left: 0;
}

.cake__frosting {
  position: relative;
}

.cake__frosting div {
  width: 20px;
  height: 20px;
  background: #e6ddb9;
  border-radius: 0 0 100px 100px;
  position: absolute;
  top: 0;
}

.cake__frosting div:nth-child(1) {
  width: 10px;
  height: 20px;
  border-radius: 0;
  left: 0;
}

.cake__frosting div:nth-child(1):after {
  content: "";
  height: 50px;
  width: 10px;
  background-color: #0000;
  border-top-right-radius: 25px;
  position: absolute;
  bottom: -50px;
  right: 0;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(2) {
  width: 10px;
  height: 80px;
  left: 10px;
}

.cake__frosting div:nth-child(2):before {
  content: "";
  height: 50px;
  width: 10px;
  background-color: #0000;
  border-top-left-radius: 25px;
  position: absolute;
  bottom: 0;
  right: -10px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(3) {
  width: 7px;
  height: 60px;
  left: 37px;
}

.cake__frosting div:nth-child(3):before {
  content: "";
  height: 50px;
  width: 10px;
  background-color: #0000;
  border-top-right-radius: 25px;
  position: absolute;
  bottom: -20px;
  right: 7px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(3):after {
  content: "";
  height: 50px;
  width: 10px;
  background-color: #0000;
  border-top-left-radius: 25px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(4) {
  width: 15px;
  height: 40px;
  left: 65px;
}

.cake__frosting div:nth-child(4):before {
  content: "";
  height: 50px;
  width: 10px;
  background-color: #0000;
  border-top-right-radius: 25px;
  position: absolute;
  bottom: -30px;
  right: 15px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(4):after {
  content: "";
  height: 50px;
  width: 5px;
  background-color: #0000;
  border-top-left-radius: 25px;
  position: absolute;
  bottom: -40px;
  right: -5px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(5) {
  width: 10px;
  height: 50px;
  left: 90px;
}

.cake__frosting div:nth-child(5):before {
  content: "";
  height: 50px;
  width: 5px;
  background-color: #0000;
  border-top-right-radius: 25px;
  position: absolute;
  bottom: -30px;
  right: 10px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(5):after {
  content: "";
  height: 50px;
  width: 10px;
  background-color: #0000;
  border-top-left-radius: 25px;
  position: absolute;
  bottom: -20px;
  right: -10px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(6) {
  width: 8px;
  height: 90px;
  left: 120px;
}

.cake__frosting div:nth-child(6):before {
  content: "";
  height: 50px;
  width: 10px;
  background-color: #0000;
  border-top-right-radius: 25px;
  position: absolute;
  bottom: 20px;
  right: 8px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(6):after {
  content: "";
  height: 80px;
  width: 10px;
  background-color: #0000;
  border-top-left-radius: 25px;
  position: absolute;
  bottom: -20px;
  right: -10px;
  box-shadow: 0 -50px #e6ddb9;
}

.cake__frosting div:nth-child(7) {
  width: 12px;
  height: 80px;
  left: 148px;
}

.cake__frosting div:nth-child(7):before {
  content: "";
  height: 80px;
  width: 10px;
  background-color: #0000;
  border-top-right-radius: 25px;
  position: absolute;
  bottom: -30px;
  right: 12px;
  box-shadow: 0 -50px #e6ddb9;
}

.cake__frosting div:nth-child(7):after {
  content: "";
  height: 80px;
  width: 10px;
  background-color: #0000;
  border-top-left-radius: 25px;
  position: absolute;
  bottom: -20px;
  right: -10px;
  box-shadow: 0 -50px #e6ddb9;
}

.cake__frosting div:nth-child(8) {
  width: 7px;
  height: 40px;
  left: 180px;
}

.cake__frosting div:nth-child(8):before {
  content: "";
  height: 50px;
  width: 10px;
  background-color: #0000;
  border-top-right-radius: 25px;
  position: absolute;
  bottom: -30px;
  right: 7px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake__frosting div:nth-child(8):after {
  content: "";
  height: 50px;
  width: 10px;
  background-color: #0000;
  border-top-left-radius: 25px;
  position: absolute;
  bottom: -30px;
  right: -10px;
  box-shadow: 0 -25px #e6ddb9;
}

.cake-stand {
  z-index: 9999;
  bottom: -60px;
}

.cake-stand__top {
  width: 240px;
  height: 6px;
  background: linear-gradient(90deg, #eee 0%, #fff 33%, #eee 100%);
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: auto;
}

.cake-stand__stem {
  width: 30px;
  height: 50px;
  background: linear-gradient(90deg, #eee 0%, #fff 33%, #eee 100%);
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin: auto;
}

.cake-stand__bottom {
  width: 140px;
  height: 10px;
  background: linear-gradient(90deg, #eee 0%, #fff 33%, #eee 100%);
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: auto;
}

.light-wrapper {
  height: 100%;
  width: 100%;
  z-index: 1;
  perspective: 500px;
  opacity: 0;
  transition: opacity .25s;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.light-wrapper--on {
  opacity: 1;
}

.light {
  height: 75%;
  width: 30px;
  transform-origin: top;
  background: linear-gradient(0deg, #fff0 0%, #ffffff80 75%);
  position: absolute;
  top: -10%;
}

.light-a {
  animation: lightA 2.9s ease-in-out infinite;
  left: 15%;
}

.light-b {
  animation: lightB 2.1s ease-in-out infinite;
  right: 15%;
}

.darkness {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: radial-gradient(circle at 50% 57%, #da693533 0%, #000000fc 20%);
  transition: opacity 2s;
  animation: glow .3s ease-in-out infinite;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.darkness--closed {
  opacity: 0;
  pointer-events: none;
}

.stagelight {
  height: 150%;
  width: 100%;
  z-index: 99999;
  pointer-events: none;
  background: linear-gradient(#000000f2 10%, #0000 100%);
  position: absolute;
  top: -100px;
  left: 0;
  overflow: hidden;
}

.stagelight__left {
  width: 50%;
  height: 100%;
  transform-origin: bottom;
  background: linear-gradient(90deg, #000000f2 75%, #0000 100%);
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateZ(-7deg);
}

.stagelight__right {
  width: 50%;
  height: 100%;
  transform-origin: bottom;
  background: linear-gradient(-90deg, #000000f2 75%, #0000 100%);
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateZ(7deg);
}

.debug {
  color: #fff;
  z-index: 999999;
  width: 100%;
  background: #000;
  padding: 10px;
  font-family: Courier New, Courier, monospace;
  font-size: .8rem;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

.debug div {
  float: left;
  margin-right: 10px;
}

/*# sourceMappingURL=index.91a186e0.css.map */
