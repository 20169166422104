@keyframes lightA {
    0% {
        transform: rotateZ(-5deg) rotateX(20deg);
    }

    50% {
        transform: rotateZ(-15deg) rotateX(30deg);
    }

    100% {
        transform: rotateZ(-5deg) rotateX(20deg);
    }
}

@keyframes lightB {
    0% {
        transform: rotateZ(5deg) rotateX(20deg);
    }

    50% {
        transform: rotateZ(15deg) rotateX(30deg);
    }

    100% {
        transform: rotateZ(5deg) rotateX(20deg);
    }
}

@keyframes glow {
    0% {
        background: radial-gradient(circle at 50% 57%, rgba(218, 105, 53, 0.2) 0%, rgba(0,0,0,0.99) 20%);
    }

    
    20% {
        background: radial-gradient(circle at 50% 57%, rgba(218, 105, 53, 0.25) 0%, rgba(0,0,0,0.99) 20.75%);
    }

    
    40% {
        background: radial-gradient(circle at 50% 57%, rgba(218, 105, 53, 0.2) 0%, rgba(0,0,0,0.99) 20.5%);
    }

    
    60% {
        background: radial-gradient(circle at 50% 57%, rgba(218, 105, 53, 0.23) 0%, rgba(0,0,0,0.99) 20.25%);
    }

    80% {
        background: radial-gradient(circle at 50% 57%, rgba(218, 105, 53, 0.2) 0%, rgba(0,0,0,0.99) 20.5%);
    }

    100% {
        background: radial-gradient(circle at 50% 57%, rgba(218, 105, 53, 0.2) 0%, rgba(0,0,0,0.99) 20%);
    }
}


html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    user-select: none;
}

body {
    background: linear-gradient(-20deg, #1697be 0%, #0c7392 100% );
    text-align: center;
    box-shadow: 0 0 0 10px #fff inset;
}


.wrapper {
    width: 100%;
    position: absolute;
    bottom: 10%;
    z-index: 9;
}

.text {
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.header {
    font-family: 'Amatic SC', cursive;
    color: #fff;
    margin-top: 20px;
    font-size: 4rem;
    z-index: 9999;
}

p {
    font-family: 'Amatic SC', cursive;
    color: #fff;
    font-size: 1.5rem;
    z-index: 9999;
    margin: 15px 0;
}

.message {
    width: 260px;
    margin: 0 auto;
}

.flame-wrapper {
    position: relative;
    top: 28px;
    z-index: 9999;
}

.flames {
    filter: blur(1px);
}

.confetti,
.sparkler,
.balloons {
    position: absolute;
    top: 0;
    left: 0;
}

.balloons {
    z-index: 1;
}

.sparkler {
    top: 100px;
    z-index: 9999;
}


.candles {
    position: absolute;
    width: 100%;
    font-family: 'Titan One', cursive;
    -webkit-text-stroke: 4px #771f18;
    text-stroke: 4px #771f18;

    div {
        display: inline-block;
        font-size: 4rem;
        color: #fff;
        position: relative;
        top: 5px;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            width: 2px;
            background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,249,226,1) 100%);
            height: 10px;
            left: 50%;
        }

        &:nth-child(1) {
            transform: rotate(-2deg);
        }

        &:nth-child(2) {
            transform: rotate(5deg);
        }

    }
    
}

.sparkler-sticks {

    div {
        position: absolute;
        width: 2px;
        height: 100px;
        background: #444;
        top: 180px;
        left: 50%;
    }

    div:nth-child(1) {        
        margin-left: 95px;
        margin-top: 15px;
        transform: rotate(13deg);
    }

    div:nth-child(2) {
        margin-left: 80px;
        transform: rotate(5deg);
    }

}

.cake {
    background: #fff9e2;
    background: linear-gradient(90deg, #771f18 0%, #83251e 33%, #771f18 100%);
    width: 200px;
    height: 125px;
    position: relative;
    margin: auto;
    margin-top: 4rem;
    border-radius: 7px 7px 2px 2px;
    overflow: hidden;

    &__band {
        background: linear-gradient(90deg, #771f18 0%, #972a22 33%, #771f18 100%);
        width: 200px;
        height: 20px;
        position: relative;
        top: 100px;
        display: none;
    }

    &__top-frosting {
        background: #e6ddb9;
        width: 100%;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__frosting{
        position: relative;
        div {
            background: #e6ddb9;
            width: 20px;
            height: 20px;
            border-radius: 100px;
            position: absolute;
            // box-shadow: 0 0 5px -3px #000000;
            top: 0;
            border-radius: 0 0 100px 100px;

            &:nth-child(1) {
                left: 0px;
                width: 10px;
                height: 20px;
                border-radius: 0;

                &:after {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -50px;
                    right: 0px;
                    height: 50px;
                    width: 10px;
                    border-top-right-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }
            }
    
            &:nth-child(2) {
                left: 10px;
                width: 10px;
                height: 80px;

                &:before {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: 0px;
                    right: -10px;
                    height: 50px;
                    width: 10px;
                    border-top-left-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }

            }

            &:nth-child(3) {
                left: 37px;
                width: 7px;
                height: 60px;


                &:before {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -20px;
                    right: 7px;
                    height: 50px;
                    width: 10px;
                    border-top-right-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }

                &:after {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -10px;
                    right: -10px;
                    height: 50px;
                    width: 10px;
                    border-top-left-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }
            }

            &:nth-child(4) {
                left: 65px;
                width: 15px;
                height: 40px;


                &:before {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -30px;
                    right: 15px;
                    height: 50px;
                    width: 10px;
                    border-top-right-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }

                &:after {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -40px;
                    right: -5px;
                    height: 50px;
                    width: 5px;
                    border-top-left-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }
            }

            &:nth-child(5) {
                left: 90px;
                width: 10px;
                height: 50px; 


                &:before {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -30px;
                    right: 10px;
                    height: 50px;
                    width: 5px;
                    border-top-right-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }

                &:after {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -20px;
                    right: -10px;
                    height: 50px;
                    width: 10px;
                    border-top-left-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }
            }

            
            &:nth-child(6) {
                left: 120px;
                width: 8px;
                height: 90px;


                &:before {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: 20px;
                    right: 8px;
                    height: 50px;
                    width: 10px;
                    border-top-right-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -20px;
                    right: -10px;
                    height: 80px;
                    width: 10px;
                    border-top-left-radius: 25px;
                    box-shadow: 0 -50px 0 0 #e6ddb9;
                }

                
            }

            
            &:nth-child(7) {
                left: 148px;
                width: 12px;
                height: 80px;


                &:before {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -30px;
                    right: 12px;
                    height: 80px;
                    width: 10px;
                    border-top-right-radius: 25px;
                    box-shadow: 0 -50px 0 0 #e6ddb9;
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -20px;
                    right: -10px;
                    height: 80px;
                    width: 10px;
                    border-top-left-radius: 25px;
                    box-shadow: 0 -50px 0 0 #e6ddb9;
                }

                
            }


            &:nth-child(8) {
                left: 180px;
                width: 7px;
                height: 40px;
              
                &:before {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -30px;
                    right: 7px;
                    height: 50px;
                    width: 10px;
                    border-top-right-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }

                &:after {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: -30px;
                    right: -10px;
                    height: 50px;
                    width: 10px;
                    border-top-left-radius: 25px;
                    box-shadow: 0 -25px 0 0 #e6ddb9;
                }

                
            }


    

        }
    }
}


.cake-stand {
    // display: none;
    bottom: -60px;
    z-index: 9999;



    &__top {
        width: 240px;
        height: 6px;
        background: linear-gradient(90deg, #eeeeee 0%, #ffffff 33%, #eeeeee 100%);
        border-radius: 10px;
        margin: auto;
        border: #ccc 1px solid;
    }

    &__stem {
        border-left: #ccc 1px solid;
        border-right: #ccc 1px solid;
        width: 30px;
        background: linear-gradient(90deg, #eeeeee 0%, #ffffff 33%, #eeeeee 100%);
        height: 50px; 
        margin: auto;

    }

    &__bottom {
        width: 140px;
        height: 10px;
        background: linear-gradient(90deg, #eeeeee 0%, #ffffff 33%, #eeeeee 100%);
        border-radius: 10px;
        margin: auto;
        border: #ccc 1px solid;
    }
}

.light-wrapper {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    perspective: 500px;
    opacity: 0;
    transition: opacity 250ms;
    overflow: hidden;

    &--on {
        opacity: 1;
    }
}

.light {
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 75%);
    position: absolute;
    top: -10%;
    height: 75%;
    width: 30px;
    transform-origin: top center;
}

.light-a {
    left: 15%;
    // transform: rotateZ(10deg) rotateX(-20deg);
    animation: lightA 2.9s ease-in-out infinite;
}

.light-b {
    right: 15%;
    // transform: rotateZ(-10deg) rotateX(-20deg);
    animation: lightB 2.1s ease-in-out infinite;
}

.darkness {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 57%, rgba(218, 105, 53, 0.2) 0%, rgba(0,0,0,0.99) 20%);
    z-index: 9999;
    transition: opacity 2000ms;
    animation: glow 300ms ease-in-out infinite;
    overflow: hidden;

    &--closed {
        opacity: 0;
        pointer-events: none;
    }
}

.stagelight {
    
    position: absolute;
    top: -100px; 
    left: 0; 
    height: 150%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0.95) 10%, rgba(0,0,0,0) 100%);
    z-index: 99999;
    pointer-events: none;
    overflow: hidden;
    

    &__left {
       background: linear-gradient(90deg, rgba(0,0,0,0.95) 75%, rgba(0,0,0,0) 100%);
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: bottom center;
        transform: rotateZ(-7deg);
    }

    &__right {
        background: linear-gradient(-90deg, rgba(0,0,0,0.95) 75%, rgba(0,0,0,0) 100%);
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        transform-origin: bottom center;
        transform: rotateZ(7deg);
    }
}

.debug {
    position: fixed;
    display: none;
    bottom: 0;
    left: 0;
    background: #000;
    color: #fff;
    font-size: .8rem;
    z-index: 999999;
    font-family: 'Courier New', Courier, monospace;
    padding: 10px;
    width: 100%;
    // display: none;

    div {
        float: left;
        margin-right: 10px;
    }
}